import React from 'react';
import Layout from '../../components/App/Layout';
import Navbar from '../../components/App/Navbar';
import ContentBanner from '../../components/Common/ContentBanner';
import Footer from '../../components/App/Footer';
import ProjectStartArea from '../../components/Common/StartProject';
import SEO from '../../components/App/SEO';
import exampleMetaImage from '../../assets/images/examples/influencer/influencer.jpg';
import InfluencerCampaign from '../../components/Examples/InfluencerCampaign';

const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="プレゼント企画のYoutube,Twitter,その他の事例・サンプル"
		  desc="Youtube、Twitter, Instagram, Twitchその他のインフルエンサーが使えるキャンペーンについての事例・サンプル集です。"
		  banner={exampleMetaImage}
		  pathname={'/examples/influencer'}
		  type={'Article'}
		  shortName="インフルエンサー"
		  createdAt="2021-03-02"
		/>
	  <Navbar />
	  <ContentBanner
		  pageTitle="インフルエンサー"
		homePageText="Home"
		homePageUrl="/"
		activePageText="インフルエンサー"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		}]}
	  />
	  <InfluencerCampaign />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples