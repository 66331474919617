import React, {useState} from 'react';
import OptimizeExample from './OptimizeExample';
import PopularExamples from './PopularExample';
import WrapperStyle from './examples.style';
import Drawer from '../../components/UiComponent/drawer';
import ScriptTag from 'react-script-tag';

const OurSolutions = () => {
  const [visible, setVisible] = useState(false);
  const [promotionId, setPromotionId] = useState(null);
  const [drawerTitle, setDrawerTitle] = useState(null);
  const showDrawer = (id, title) => {
    setVisible(true);
    setPromotionId(id);
    setDrawerTitle(title)
  };
    return (
      <WrapperStyle>
          <section className="solutions-area pt-100 pb-70">
              <div className="container">
                  <div className="section-title">
                      <h1>インフルエンサー向けプレゼント企画
                      </h1>
                      <p>
                          YouTube以外のTwitchやツイキャスでもプレゼント企画を行うことができます。フォローするアカウントを複数指定できるため、スポンサーに賞品を提供してもらう代わりにスポンサーのアカウントをフォローする条件などを設定できます。
                      </p>
                  </div>
                  <div className="row">
                      <div className="col-lg-12 col-sm-12">
                          <a data-retach-widget="embed" href="https://retach.biz/promotions/ae7eb3f81ed7fbf639" rel="nofollow">リタッチ</a>
                        <ScriptTag  type="text/javascript" src="https://retach.biz/scripts/promotionCall.js"/>
                      </div>
                  </div>
              </div>
          </section>
          <OptimizeExample types={['companyCsr','keepWatching','influencer']} showDrawer={showDrawer}/>
          <PopularExamples exclude={['companyCsr','keepWatching', 'influencer']} showDrawer={showDrawer}/>
        <Drawer visible={visible}id={promotionId} drawerTitle={drawerTitle} setVisible={setVisible}/>
      </WrapperStyle>
    )
};

export default OurSolutions